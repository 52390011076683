import React, { useContext, useEffect, useState, Fragment } from "react";
import { navigate, Link } from "gatsby";

import DataTable from "../../../Templates/DataTable";
import AppContext from "../../../../context/AppContext";
import AdminBatchUploadRow from "../AdminBatchUploadRow/AdminBatchUploadRow";

import headers from "../../../../assets/batchUploadTableHeaders.json";
import api from "../../../../services/api";
import classNames from "classnames";
import Footer from "../../../Templates/Footer";
import Loader from "../../../Templates/Loader";
import Pagination from "../../../Templates/Pagination";

import {
  loadingProgressBar,
  overflowTable,
  fixedTable,
  addBottomMargin,
} from "./progress.module.scss";
import AdminBatchUploadConfirmModal from "./AdminBatchUploadConfirmModal";

const AdminBatchUploadPreview = () => {
  const appContext = useContext(AppContext);
  const [batchUploadData, setBatchUploadData] = useState({
    type: "",
    data: [],
  });
  const [initialLength, setInitialLength] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [maxUploadLength, setMaxUploadLength] = useState(500);
  const [isLoading, setIsLoading] = useState(true);
  const [pages, setPages] = useState({
    length: 1,
    min: 0,
    max: 499,
  });

  useEffect(async () => {
    if (!appContext.batchUploadData.data) navigate("admin/batch-upload");
    else {
      const uploadData = appContext.batchUploadData;
      await setBatchUploadData(uploadData);
      setInitialLength(uploadData.data.length);
      setPages({ ...pages, length: Math.ceil(uploadData.data.length / 500) });
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isUploading && batchUploadData.data.length > 0) {
      uploadData(batchUploadData.type);
    }
  }, [batchUploadData.data]);

  const handleClick = type => {
    uploadData(type);
  };

  const uploadData = type => {
    if (!isUploading) setIsUploading(true);
    api
      .post(`${type}/insert/`, {
        [type]: batchUploadData.data.slice(0, maxUploadLength),
      })
      .then(response => {
        if (
          response.data.pending.length > 0 ||
          batchUploadData.data.slice(maxUploadLength).length > 0
        ) {
          setBatchUploadData({
            ...batchUploadData,
            data: [
              ...response.data.pending,
              ...batchUploadData.data.slice(maxUploadLength),
            ],
          });
          setPages({
            length: Math.ceil(response.data.pending.length / 500),
            min: 0,
            max: 499,
          });
        } else {
          setIsUploading(false);
          setBatchUploadData({
            ...batchUploadData,
            data: [],
          });
          appContext.useToast(
            "Successfully uploaded CSV file.",
            "is-success",
            5000
          );
          navigate("/admin/batch-upload");
        }
      })
      .catch(error => {
        setIsUploading(false);
      });
  };

  const callbackFunction = pageNumber => {
    setPages({
      ...pages,
      min: (pageNumber - 1) * 500,
      max: pageNumber * 500 - 1,
    });
  };

  // need to extract <Loader>
  //
  // if(isLoading) return <Loader>...</Loader>
  return (
    <div className="container">
      {isLoading ? (
        <Loader>Loading data, please wait...</Loader>
      ) : (
        <Fragment>
          <h1 className="title is-size-4 has-text-primary">
            Total no. of {batchUploadData.type || ""}:{" "}
            <span className="has-text-black">
              {batchUploadData.data && batchUploadData.data.length} (
              {batchUploadData.data
                ? initialLength - batchUploadData.data.length
                : 0}
              /{initialLength} done)
            </span>
          </h1>
          <progress
            className={classNames("progress is-primary", loadingProgressBar)}
            value={
              batchUploadData.data
                ? initialLength - batchUploadData.data.length
                : 0
            }
            max={initialLength}
          />
          <div className={classNames(overflowTable)}>
            <DataTable
              headerTitles={headers[batchUploadData.type || "employees"]}
              tableClassNames={classNames(overflowTable, fixedTable)}
            >
              {batchUploadData.data &&
                batchUploadData.data.map((item, index) => {
                  if (index >= pages.min && index <= pages.max)
                    return (
                      <AdminBatchUploadRow
                        data={item}
                        type={batchUploadData.type}
                        index={index}
                      />
                    );
                })}
            </DataTable>
          </div>
          <div className={classNames("mt-3", addBottomMargin)}>
            {pages.length > 1 && (
              <Pagination
                pages={pages.length}
                callbackFunction={callbackFunction}
              />
            )}
          </div>

          <Footer footerClassnames={"has-background-light"}>
            <div className="buttons is-centered">
              <Link to="admin/batch-upload">
                <button className="button is-primary is-outlined mr-1">
                  Back to Batch Upload Home
                </button>
              </Link>
              <button
                className={classNames("button is-primary ml-1", {
                  "is-loading": isUploading,
                })}
                onClick={() => {
                  appContext.useModal(
                    <AdminBatchUploadConfirmModal
                      closeModal={appContext.closeModal}
                      uploadData={() => {
                        appContext.closeModal();
                        handleClick(batchUploadData.type);
                      }}
                    />,
                    "grey",
                    "Are you sure?"
                  );
                }}
              >
                Confirm and Upload data
              </button>
            </div>
          </Footer>
        </Fragment>
      )}
    </div>
  );
};

export default AdminBatchUploadPreview;
