import React from "react";
import moment from "moment";
import classNames from "classnames";
import { width200 } from "../AdminBatchUploadPreview/progress.module.scss";

const BatchEmployee = ({
  department,
  email,
  employeeNumber,
  firstName,
  lastName,
  position,
  sex,
  siteName,
  index,
}) => (
  <tr key={index}>
    <td>{index + 1}</td>
    <td>{siteName}</td>
    <td>{firstName}</td>
    <td />
    <td>{lastName}</td>
    <td>{sex}</td>
    <td>{employeeNumber}</td>
    <td>{position}</td>
    <td>{department}</td>
    <td>{email}</td>
  </tr>
);

const BatchConsultations = ({
  index,
  date,
  nurseFirstName,
  nurseLastName,
  siteName,
  employeeNumber,
  firstName,
  lastName,
  department,
  chiefComplaint1,
  chiefComplaint2,
  recommendation,
  historyOfPresentIllness,
  weightKg,
  heightCm,
  systolicMmhg,
  diastolicMmhg,
  heartRateBpm,
  respiratoryRateBpm,
  bodyTemperatureC,
  remarkableFindings,
  assessment,
  assessmentNotes,
  diagnosis,
  diagnosisNotes,
  medicinesDispensed1,
  medicinesQuantity1,
  medicinesDispensed2,
  medicinesQuantity2,
  notes,
  customFields,
}) => (
  <tr key={index}>
    <td>{index + 1}</td>
    <td>{moment(date).format("h:mm A MMM DD, YYYY")}</td>
    <td>{siteName}</td>
    <td>{`${nurseFirstName} ${nurseLastName}`}</td>
    <td>{employeeNumber}</td>
    <td>{`${firstName} ${lastName}`}</td>
    <td>{department}</td>
    <td>
      {chiefComplaint1}
      {chiefComplaint2 && `, ${chiefComplaint2}`}
    </td>
    <td>{historyOfPresentIllness}</td>
    <td>{weightKg}</td>
    <td>{heightCm}</td>
    <td>
      {systolicMmhg && diastolicMmhg ? `${systolicMmhg}/${diastolicMmhg}` : ""}
    </td>
    <td>{heartRateBpm}</td>
    <td>{respiratoryRateBpm}</td>
    <td>{bodyTemperatureC}</td>
    <td>{remarkableFindings}</td>
    <td>
      {assessment}
      {assessmentNotes && `(${assessmentNotes})`}
    </td>
    <td>
      {diagnosis}
      {diagnosisNotes && `(${diagnosisNotes})`}
    </td>
    <td>
      <div className={classNames(width200)}>
        {medicinesDispensed1 && `${medicinesDispensed1} #${medicinesQuantity1}`}
        {medicinesDispensed2 &&
          `, ${medicinesDispensed2} #${medicinesQuantity2}`}
      </div>
    </td>
    <td>{recommendation}</td>
    <td>{notes}</td>
    <td>{customFields}</td>
  </tr>
);

const AdminBatchUploadRow = ({ data, type, index }) => {
  switch (type) {
    case "employees":
      return <BatchEmployee {...data} index={index} />;
    case "consultations":
      return <BatchConsultations {...data} index={index} />;
    default:
      return null;
  }
};

export default AdminBatchUploadRow;
