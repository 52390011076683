import React from "react";

const AdminBatchUploadConfirmModal = ({ closeModal, uploadData }) => {
  return (
    <div>
      <p className="my-2 has-text-centered is-size-5">
        Do you want to proceed and upload this data?{" "}
        <span className="has-text-weight-bold">
          This action cannot be undone.
        </span>
      </p>
      <div className="buttons is-centered">
        <button
          className="button is-primary is-outlined mr-1"
          onClick={closeModal}
        >
          No, go back and review my data
        </button>
        <button className="button is-primary mr-1" onClick={uploadData}>
          Yes, proceed with the upload
        </button>
      </div>
    </div>
  );
};

export default AdminBatchUploadConfirmModal;
