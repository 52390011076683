import React from "react";
import Layout from "../../../layout/Layout";
import AdminBatchUploadPreview from "../../../components/Admin/BatchUpload/AdminBatchUploadPreview/AdminBatchUploadPreview";
import SEO from "../../../layout/Seo";

export default () => (
  <Layout pageTitle="Batch Upload Preview">
    <SEO title="Batch Upload Preview" />
    <AdminBatchUploadPreview />
  </Layout>
);
